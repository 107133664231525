<template>
  <div id="HomePage">
    <header>
      <div class="header-box">
        <div class="header-left">
          <div>我的产品</div>
          <img src="#" alt="" />
        </div>
        <div class="header-right"></div>
      </div>
    </header>
  </div>
</template>

<script>
import API from '@/api';
import cookies from '@/utils/cookies';

export default {
  name: 'HomePage',
  created() {
    const IsCookies = cookies.get('token');
    if (!IsCookies) {
      window.open(`${process.env.VUE_APP_LOGIN}login`);
    } else {
      API.getPrinting().then((res) => {
        const printingToken = res.message.data;
        cookies.set('printingToken', printingToken);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
header {
  height: 250px;
  margin: auto;
  background: #888888;
  position: relative;
  .header-box {
    width: 1200px;
    position: absolute;
    top: 125px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    .header-left {
      width: 780px;
      height: 200px;
      margin-right: 20px;
      border: 1px solid teal;
    }
    .header-right {
      width: 390px;
      height: 200px;
      margin-right: 20px;
      border: 1px solid teal;
    }
  }
}
</style>
